html {
  background-color: #fff;
  color: #333;
}

body {
  font-family: "Trebuchet MS", sans-serif;
  font-size: 14px;
}

/**
 * Base CSS for HTML elements
 */
 h1 {
  font-family: "Cardo", serif;
  color: #337258;
  font-size: 2em;
  margin: 0 0 1.5em;
}
h2 {
  font-family: "Cardo", serif;
  font-size: 1.7em;
  color: #555;
  margin: 2em 0 .7em;
}
h3 {
  font-family: "Cardo", serif;
  font-size: 1.3em;
  color: #666;
  margin: 1.5em 0 .5em;
  font-style: italic;
}
h1.first, h2.first, h3.first {
  margin-top: 0;
}
p {
  margin: 0 0 1.5em;
  line-height: 1.7em;
}
a {
  color: #016C93;
}
ul, ol {
  padding-left: 30px;
  margin: 1em 0px;
}
ul li {
  list-style: disc;
  padding: 2px;
}
ol li {
  list-style: decimal;
  padding: 2px;
}


/**
* Tables
*/
table {
  margin: 2em 0;
  padding: 0;
}
thead th {
  
}
tbody th {
  text-align: left;
  color: #337258;
}
td, th {
  padding: 5px 10px;
}


/**
* Contact table
*/
table.contact {
  background-color: #efefef;
  width: 100%;
}
table.contact tbody th {
  width: 16px;
}
table.contact tbody th img {
  vertical-align: middle;
}

/**
 * Attorney sidebar
 */
 ul.attorneys {
  margin: 0;
  padding: 0;
}
ul.attorneys li {
  list-style: none;
  padding: 5px 0px;
  margin: 0px;
}
ul.attorneys li a {
  display: block;
  position: relative;
  padding-left: 60px;
  text-decoration: none;
  font-size: 1.2em;
  color: #337258;
  height: 50px;
  line-height: 50px;
}
ul.attorneys li a:hover, ul.attorneys li a:focus {
  background-color: #eee;
}
ul.attorneys li a img.attorney-thumb {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 40px;
}


/**
 * Attorney headshots
 */
#sidebar {
  .headshot {
    width: 100%;
    max-width: 340px;
  }
}