#header-container {
  background-color: #fff;
}
#header-banner {
  line-height: 0;
  
  img { width: 100%; }
}

@media only screen and (min-width: $width_mobile) {
  #header-content {
    padding: 10px;  
    padding-bottom: 0;
  }
}
